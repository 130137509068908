<template>
  <section class="slider">
    <ul class="slider--home">
      <router-link
        :to="{ path: slides.slug }"
        class="slider--home__item item--home"
        @mouseover="activeLink = slides.id"
        v-on:click.prevent="activeLink = slides.id"
        :class="{ active: slides.id == activeLink }"
        v-for="(slides, index) in slider"
        :key="slides.id"
      >
        <v-lazy-image
          :src="url + slides.image"
          width="1104"
          height="918"
          rel="preload"
          :alt="'slider-' + index"
        />
        <div class="item--home__wrap">
          <div class="item--home__inner">
            <div class="item--home__title">
              <v-lazy-image
                :src="iconUrl[index]"
                width="60"
                height="60"
                rel="preload"
                :alt="'slider-' + index"
              />
              <h2 class="item--home__title--text">
                <pre>{{ slides.name }}</pre>
              </h2>
            </div>
            <div class="item--home__descr">
              <div v-html="slides.description"></div>
              <span class="item--home__descr--link">
                {{ t("more_details") }}
              </span>
            </div>
          </div>
        </div>
      </router-link>
    </ul>
  </section>
</template>

<script>
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import VLazyImage from "v-lazy-image";

export default defineComponent({
  setup() {
    const { t } = useI18n();
    return {
      t,
    };
  },
  components: {
    VLazyImage,
  },
  props: {
    url: {
      type: String,
      default: process.env.VUE_APP_API,
    },
    slider: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      window: {
        width: 0,
      },
      show: true,
      activeLink: 3,
    };
  },
  computed: {
    iconUrl() {
      return [
        require("@/assets/images/slider--home-1.svg"),
        require("@/assets/images/slider--home-2.svg"),
        require("@/assets/images/slider--home-3.svg"),
        require("@/assets/images/slider--home-4.svg"),
        require("@/assets/images/slider--home-5.svg"),
        require("@/assets/images/slider--home-6.svg"),
      ];
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.window.width = window.innerWidth;

      if (this.window.width >= 576) {
        this.show = true;
      } else {
        this.show = false;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/_mixins.scss";

$baseTr: 0.6rem;

.slider {
  position: relative;
  z-index: 30;
  @include property("margin-bottom", 170, 40);

  &--home {
    display: flex;
    overflow: hidden;

    @media (max-width: 650px) {
      flex-direction: column;
    }
  }
}

@keyframes move-chevron {
  33.3% {
    opacity: 0.6;
    transform: translateY($baseTr * 3.8);
  }

  66.6% {
    opacity: 1;
    transform: translateY($baseTr * 5.2);
  }

  100% {
    opacity: 0;
    transform: translateY($baseTr * 8) scale(0.5);
  }
}

.item--home {
  position: relative;
  z-index: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 15px;
  transition: width 0.3s linear;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    z-index: 1;
  }

  &:not(.active) {
    cursor: pointer;
  }

  > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    object-fit: cover;
    z-index: 2;
    opacity: 0.25;
  }

  &__wrap {
    position: relative;
    z-index: 5;
    width: 100%;

    &::before {
      content: "";
      position: absolute;
      top: 10px;
      left: -15px;
      width: 3px;
      height: 54px;
      background: #f9b80e;
    }
  }

  &__inner {
    max-width: 100%;
    margin: 0 auto;
    overflow: hidden;
  }

  &__title {
    display: flex;
    flex-wrap: wrap;

    &--text {
      position: relative;
      display: flex;
      align-items: center;
      color: #f9b80e;
      text-transform: uppercase;
      font-weight: 700;
      line-height: calc(25 / 16);
      letter-spacing: 0.8px;
      width: calc(100% - 45px);
      font-size: 24px;
      white-space: initial;
    }

    img {
      flex: 0 0 auto;
      max-width: max-content;
      height: 30px;
      margin-bottom: 10px;
      margin-right: 15px;
    }
  }

  &__descr {
    font-size: 18px;
    line-height: calc(30 / 18);
    color: #e1e1e1;
    margin-top: 5px;
    margin-bottom: 0;

    &--link {
      line-height: calc(30 / 16);
      letter-spacing: 2.4px;
      color: #f9b80e;
    }

    > div {
      max-width: 550px;
      max-height: 90%;
      margin-bottom: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -moz-box;
      -moz-box-orient: vertical;
      display: -webkit-box;
      -webkit-line-clamp: 9;
      -webkit-box-orient: vertical;
      line-clamp: 9;
      box-orient: vertical;

      @media (max-width: 650px) {
        display: none;
      }

      @media (max-height: 699.98px) {
        display: none;
      }
    }
  }
}
</style>
